@charset "utf-8";

*{
 box-sizing: border-box !important;
 margin: 0px;
 padding: 0;
}

body
{
 box-sizing: border-box !important;
 font-family: 'Helvetica', 'Arial';
 height: auto;
 margin: 0;
 padding: 0;
 width: auto;
}

html
{
 box-sizing: border-box !important;
 font-size: 1rem;
}

a{
 color: $black;
}

a:focus, a:hover{
 color: $blue_dark;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button
{
 -webkit-appearance: none;
 margin: 0;
}

input[type=number]{
 -moz-appearance:textfield;
}

.alert-outline-blue
{
 background-color: $white;
 border: 1.2px solid $blue;
 color: $black;
}

.alert-outline-purple
{
 background-color: $white;
 border: 1.2px solid $purple;
 color: $purple;
}

.dropdown-menu .dropdown-item:focus,
.dropdown-menu .dropdown-item:hover,
.dropdown-menu a:active,
.dropdown-menu a:focus,
.dropdown-menu a:hover{
 background-color: $blue !important;
}

.bg-blue{
 background-color: $blue;
}

.bg-purple{
 background-color: $purple;
}

.box-blue
{
 border: 1px solid $blue;
 margin: 0.25rem;
 padding: 0.25rem;
 text-align: justify;
}

.btn-blue
{
 background-color: $blue !important;
 border-color: $blue !important;
 box-shadow: none;
 color: $white !important;
}

.btn-blue:hover
{
 background-color: $white !important;
 border-color: $blue !important;
 color: $blue !important;
}

.btn-blue:focus,
.btn-blue.focus{
 box-shadow: none, 0 0 0 0.2rem rgba($blue, 0.5) !important;
}

.btn-blue.disabled,
.btn-blue:disabled
{
 background-color: $blue !important;
 border-color: $blue !important;
 color: $white !important;
}

.btn-blue:not(:disabled):not(.disabled):active,
.btn-blue:not(:disabled):not(.disabled).active,
.show>.btn-blue.dropdown-toggle
{
 background-color: $blue;
 border-color: $white;
 color: $white;
}

.btn-blue:not(:disabled):not(.disabled):active:focus,
.btn-blue:not(:disabled):not(.disabled).active:focus,
.show>.btn-blue.dropdown-toggle:focus{
 box-shadow: none, 0 0 0 0.2rem rgba($blue, 0.5) !important;
}

.btn-outline-blue
{
 background-color: $white;
 border: 1.2px solid $blue;
 color: $blue;
}

.btn-outline-blue:hover
{
 background-color: $blue;
 border-color: $blue;
 color: $white;
}

.btn-outline-blue:focus, .btn-outline-blue.focus{
 box-shadow: 0 0 0 0.2rem rgba($blue, 0.5);
}

.btn-outline-purple
{
 background-color: $white;
 border: 1.2px solid $purple;
 color: $purple;
}

.btn-outline-purple:hover
{
 background-color: $purple;
 border-color: $purple;
 color: $white;
}

.btn-outline-purple:focus, .btn-outline-purple.focus{
 box-shadow: 0 0 0 0.2rem rgba($purple, 0.5);
}

.btn-outline-transparent
{
 background-color: $white;
 border-color: transparent;
 color: $blue;
}

.btn-outline-transparent:hover
{
 background-color: $blue;
 border-color: $white;
 color: $white;
}

.btn-outline-transparent:focus, .btn-outline-transparent.focus
{
 background-color: $white;
 border-color: transparent;
 color: $blue;
}

.btn-outline-transparent.disabled, .btn-outline-transparent:disabled
{
 background-color: transparent;
 color: $blue;
}

.btn-outline-transparent:not(:disabled):not(.disabled):active, .btn-outline-transparent:not(:disabled):not(.disabled).active,
.show > .btn-outline-transparent.dropdown-toggle
{
 background-color: $blue;
 border-color: $white;
 color: $white;
}

.btn-outline-transparent:not(:disabled):not(.disabled):active:focus, .btn-outline-transparent:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-transparent.dropdown-toggle:focus
{
 background-color: $white;
 border-color: transparent;
 color: $blue;
}

.card .card-header-blue .card-icon,
.card .card-header-blue .card-text,
.card .card-header-blue:not(.card-header-icon):not(.card-header-text),
.card.bg-blue,
.card.card-rotate.bg-blue .front,
.card.card-rotate.bg-blue .back {
 background: linear-gradient(60deg, $blue, $blue_dark);
}

.card .card-header-blue-light .card-icon,
.card .card-header-blue-light .card-text,
.card .card-header-blue-light:not(.card-header-icon):not(.card-header-text),
.card.bg-blue-light,
.card.card-rotate.bg-blue-light .front,
.card.card-rotate.bg-blue-light .back {
 background: linear-gradient(60deg, $blue_light, $white);
}

.container-header
{
 align-items: center;
 background-color: $white;
 border-radius: 0.25rem;
 display: flex;
 flex-wrap: wrap;
 justify-content: space-between;
 list-style: none;
 margin: 0;
 padding: 0.75rem 1rem;
}

.container-radio
{
 align-items: center;
 display: flex;
 flex-direction: row;
 flex-wrap: wrap;
 justify-content: center;
 margin: 0;
}

.container-search
{
 background-color: rgba($white, .8);
 border-radius: 6px;
 border: 0;
 display: flex;
 flex-direction: row;
 flex-wrap: wrap;
 justify-content: flex-start;
 margin: 1rem auto;
 padding: 0;
}

.cursor-pointer{
 cursor: pointer;
}

.dropdown-menu.bootstrap-datetimepicker-widget
{
 opacity: 1 !important;
 transform: scale(1) !important;
}

.form-control, .is-focused .form-control{
 background-image: linear-gradient(0deg, $blue_dark 2px, rgba(156, 39, 176, 0) 0), linear-gradient(0deg, #d2d2d2 1px, rgba(209, 209, 209, 0) 0) !important;
}

.form-error
{
 color: $red;
 margin: 0;
 padding: 0;
 text-align: center;
}

.list_header
{
 background-color: $white;
 display: flex;
 margin: 0;
 padding: 0;
}

.list_header__item
{
 display: flex;
 margin: 0;
 padding: 0;
}

.list_header__item::after{
 content: "\0000a0 \0000a0 / \0000a0";
}

.list_header__item:last-child::after{
 content: "";
}

.main
{
 align-items: stretch;
 background-color: $white;
 background-position: center;
 background-repeat: no-repeat;
 background-size: initial;
 margin: 0;
 padding: 0;
 width: auto;
}

.section-article
{
 background-image: url('/img/elements/fondo_1.png');
 background-position: center;
 background-attachment: fixed;
 background-size: cover;
 align-items: center;
 display: flex;
 height: 100vh;
 justify-content: center;
 margin: 0;
 width: 100%;
}

.selected
{
 background-color: $blue;
 color: $white;
 font-weight: bolder;
}

.select2{
 width: 100% !important;
}

.sidebar[data-color=blue] li.active > a
{
 background-color: $blue !important;
 color: $white !important;
}

.sidebar .nav li.active > [data-toggle=collapse]
{
 background-color: $blue !important;
 color: $white !important;
}

.sidebar .nav li .dropdown-menu a, .sidebar .nav li a{
 padding: 5px 10px;
}

.sweet-alert
{
 box-sizing: border-box;
 max-height: 100% !important;
 overflow-y: auto !important;
 padding: 0 17px 17px !important;
 width: 512px;
}

.sweet-alert:before
{
 content: "";
 display: block;
 height: 17px;
 width: 0;
}

.text-blue{
 color: $blue;
}

.userFile
{
 height: 0.1px;
 opacity: 0;
 overflow: hidden;
 position: absolute;
 width: 0px;
 z-index: -1;
}

.vertical-initial{
 vertical-align: initial !important;
}

.w-15{
 width: 15%;
}

.w-20{
 width: 20%;
}

.w-30{
 width: 30%;
}

.w-40{
 width: 40%;
}

.w-45{
 width: 45%;
}

.w-60{
 width: 60%;
}

.w-65{
 width: 65%;
}

@media(min-width:1px) and (max-width:320px)
{
 .font-size-1{
  font-size: .48rem;
 }
}

@media(min-width:321px) and (max-width:575px)
{
 .font-size-1{
  font-size: .51rem;
 }
}

@media(min-width:576px) and (max-width:767px)
{
 .font-size-1{
  font-size: .54rem;
 }
}

@media(min-width:768px) and (max-width:991px)
{
 html{
  font-size: 1.1rem;
 }

 .font-size-1{
  font-size: .5rem;
 }
}

@media(min-width:992px) and (max-width:1199px)
{
 html{
  font-size: 1.1rem;
 }

 .font-size-1{
  font-size: .53rem;
 }
}

@media(min-width:1200px) and (max-width:1399px)
{
 html{
  font-size: 1.2rem;
 }

 .font-size-1{
  font-size: .55rem;
 }
}

@media(min-width:1400px)
{
 html{
  font-size: 1.3rem;
 }

 .font-size-1{
  font-size: .57rem;
 }
}

@media(orientation: landscape)
{
 @media(min-width:1px) and (max-width:320px)
 {
  .section-article{
   height: auto !important;
  }
 }
 @media(min-width:321px) and (max-width:575px)
 {
  .section-article{
   height: auto !important;
  }
 }
 @media(min-width:576px) and (max-width:767px)
 {
  .section-article{
   height: auto !important;
  }
 }
}
